import React, { useEffect } from "react";
import "./Hero.css";
import { Box, Grid, Avatar, Typography, Button, Stack } from "@mui/material";
import hero_img from "../../assets/Ashutosh.jpg";
import { HashLink } from "react-router-hash-link";
import Ashutosh_resume from "../../assets/Ashutosh kumar Resume.pdf";
import Aos from 'aos'
import 'aos/dist/aos.css'
const Hero = () => {
  useEffect(()=>{
    Aos.init();
  })
  return (
    <Box id="home" sx={{ background: 'linear-gradient(115deg, #ffffff, #ffe3fb)' }}>
      <Grid
        height={"100%"}
        container
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ padding:{xs:"80px 40px", sm:"200px 40px"} }}
      >
        {/* left */}
        <Grid item xs={12} sm={8} mb={2}>
          <Typography
            sx={{
              fontSize: { xs: "2rem", sm: "2.5rem" },
              letterSpacing: ".1em",
            }}
            mb={2}
            fontWeight={"bold"}
            className="hero_name"
          >
            I'm <span >Ashutosh Kumar</span>
          </Typography>
          <Typography
            sx={{ marginBottom: 1, letterSpacing: ".5em", fontWeight: "bold" }}
            className="hero_title"
          >
           Fullstack Developer
          </Typography>
          <Typography
            className="hero_info"
            sx={{
              width: { xs: "100%", sm: "90%" },
              color: "#535c68",
              lineHeight: "1.7",
              fontSize: "1.1rem",
              letterSpacing: ".1em",
            }}
          >
            I specialize in creating modern and responsive web applications. With a strong passion for front-end development, I bring creative solutions to life through code and design. Additionally, I am a winner 🏆 of the <span style={{fontWeight:"bold"}}>GDG Patna  WEB Hackathon </span> 
          </Typography>

          <Stack
            direction={"row"}
            gap={2}
            mt={4}
            sx={{ marginBottom: { xs: "2rem" } }}
            
          >
            <a href={Ashutosh_resume} download>
              <Button sx={{borderRadius:"50px",color:"white"}} variant="contained" color="ternary">
                Download Resume
              </Button>
            </a>
            <HashLink smooth to="#projects">
              <Button sx={{borderRadius:"50px",color:"white"}} variant="contained" color="secondary">
                Projects
              </Button>
            </HashLink>
          </Stack>
        </Grid>

        {/* right */}
        <Grid item xs={12} sm={4}>
          <Box
            sx={{ width: "100%" }}
            display={"flex"}
            justifyContent={"center"}
            data-aos="zoom-in"
          >
            <Avatar
              alt="hero_img"
              sx={{
                aspectRatio: "1/1",
                width: { xs: "250px", sm: "300px" },
                height: "100%",
                "&:hover":{
                  transform:" scale(1.1)",
                  transition:"all .5s ease-in-out",
                  cursor:"pointer"
                }
              }}
              src={hero_img}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;
